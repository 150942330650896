module.exports = [{
      plugin: require('/Users/paul/Projects/Gene/goodhead.ca/gatsby-site/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/paul/Projects/Gene/goodhead.ca/gatsby-site/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":null,"head":false,"exclude":[]},
    },{
      plugin: require('/Users/paul/Projects/Gene/goodhead.ca/gatsby-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
