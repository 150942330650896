const browserLang = require('browser-lang').default
const { navigate, withPrefix } = require('gatsby')

const languages = require('./src/languages')

exports.onClientEntry = () => {
  // Add Intl polyfill support
  if (global.Intl) {
    import('intl')
      .then(() =>
        Promise.all([
          import('intl/locale-data/jsonp/en.js'),
          import('intl/locale-data/jsonp/fr.js'),
        ])
      )
      .catch(error => {
        throw error
      })
  }
}

exports.onPreRouteUpdate = ({ location }) => {
  // Redirect users to their prefered language endpoints
  const isLanguageDefined = languages.locales.includes(
    location.pathname.split('/')[1]
  )

  if (!isLanguageDefined) {
    const browserLocale = browserLang({
      languages: languages.locales,
      fallback: languages.defaultLocale,
    })

    navigate(withPrefix(`/${browserLocale}${location.pathname}`), {
      replace: true,
    })
  }
}
